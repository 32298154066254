<template>
  <div>
    <div class="range-slider">
      <input 
        type="range" 
        class="min-val" 
        v-model="minValue" 
        :min="sliderMinValue" 
        :max="sliderMaxValue" 
       
        @input="slideMin"
      />
      <input 
        type="range" 
        class="max-val" 
       
        v-model="maxValue" 
        :min="sliderMinValue" 
        :max="sliderMaxValue" 
        @input="slideMax"
      />
      <div class="slider-track"></div>
      <!-- <div class="min-tooltip px-2 py-2 rounded bg-dark text-light">{{ minValue }}</div>
      <div class="max-tooltip px-2 py-2 rounded bg-dark text-light">{{ maxValue }}</div> -->
    </div>
    <div class="d-flex gap">
      <div class="" style="overflow: hidden;">
        <input 
          type="number" 
          class="flow-select" 
          placeholder="Min"
          v-model="minInputValue" 
          @input="setMinInput"
        />
      </div>
      <div class=" " style="overflow: hidden;">
        <input 
          type="number" 
          class="flow-select" 
          placeholder="Max"
          v-model="maxInputValue" 
          @input="setMaxInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  watch:{
    minValue(value){
      this.emit('change',{min:value, max:this.maxValue})
    },
    maxValue(value){
      this.emit('change',{max:value, min:this.minValue})
    }
  },
  data() {
    return {
      minValue: 0,
      maxValue: 10000,
      minInputValue: 0,
      maxInputValue: 10000,
      minGap: 10,
      sliderMinValue: 0,
      sliderMaxValue: 10000,
    };
  },
  methods: {
    invisibleTooltip(){
      let minToolTip= this.$el.querySelector(".min-tooltip");
      let maxTooltip = this.$el.querySelector(".max-tooltip");
      function hide(el){

       el.style.display = 'none';
              setTimeout(() => {
                el.style.opacity = '0';
              }, 0);
      }
      hide(minToolTip)
      hide(maxTooltip)
    },
    visibleTooltip(){
      let minToolTip= this.$el.querySelector(".min-tooltip");
      let maxTooltip = this.$el.querySelector(".max-tooltip");
      function show(el){

       el.style.display = 'block';
              setTimeout(() => {
                el.style.opacity = '1';
              }, 0);
      }
      show(minToolTip)
      show(maxTooltip)
      
    },
    slideMin() {
      if (parseInt(this.maxValue) - parseInt(this.minValue) < this.minGap) {
        this.minValue = parseInt(this.maxValue) - this.minGap;
      }
      this.minInputValue = this.minValue;
      this.setArea();
    },
    slideMax() {
      if (parseInt(this.maxValue) - parseInt(this.minValue) < this.minGap) {
        this.maxValue = parseInt(this.minValue) + this.minGap;
      }
      this.maxInputValue = this.maxValue;
      this.setArea();
    },
    setArea() {
      const range = this.$el.querySelector(".slider-track");
      // const minTooltip = this.$el.querySelector(".min-tooltip");
      // const maxTooltip = this.$el.querySelector(".max-tooltip");
      range.style.left = `${((this.minValue - this.sliderMinValue) / (this.sliderMaxValue - this.sliderMinValue)) * 100}%`;
      // minTooltip.style.left = `${(((this.minValue) / this.sliderMaxValue) * 90)}%`;
      range.style.right = `${100 - ((this.maxValue - this.sliderMinValue) / (this.sliderMaxValue - this.sliderMinValue)) * 100}%`;
      // maxTooltip.style.right = `${(100 - (this.maxValue / this.sliderMaxValue) * 100)-5}%`;
    },
    setMinInput() {
      this.minValue = parseInt(this.minInputValue);
      this.slideMin();
    },
    setMaxInput() {
      this.maxValue = parseInt(this.maxInputValue);
      this.slideMax();
    },
  },
  mounted() {
    this.slideMin();
    this.slideMax();
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  height: 100vh;
  display: grid;
  background-color: #0b0f19;
  place-items: center;
  font-family: 'Poppins', sans-serif;
}

.double-slider-box {
  background-color: #fff;
  padding: 20px 40px;
  border-radius: 10px;
  max-width: 20rem;
}

h3.range-title {
  margin-bottom: 4rem;
}

.range-slider {
  position: relative;
  width: 100%;
  height: 5px;
  margin: 30px 0;
  background-color: #8a8a8a;
}

.slider-track {
  height: 100%;
  position: absolute;
  background-color: var(--primary-color);
}

.range-slider input {
  position: absolute;
  width: 100%;
  background: none;
  pointer-events: none;
  top: 50%;
  transform: translateY(-50%);
  appearance: none;
  z-index:1;
}

input[type="range"]::-webkit-slider-thumb {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 3px solid #FFF;
  background: #FFF;
  pointer-events: auto;
  appearance: none;
  cursor: pointer;
  box-shadow: 0 .125rem .5625rem -0.125rem rgba(0, 0, 0, .25);
}

input[type="range"]::-moz-range-thumb {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 3px solid #FFF;
  background: #FFF;
  pointer-events: auto;
  -moz-appearance: none;
  cursor: pointer;
  box-shadow: 0 .125rem .5625rem -0.125rem rgba(0, 0, 0, .25);
}

.input-box {
  display: flex;
}

.min-box,
.max-box {
  width: 50%;
}

.min-box {
  padding-right: .5rem;
  margin-right: .5rem;
}

.input-wrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.min-tooltip {
  position: absolute;
    z-index: 1;
    top: 20px;
    display: none;
            opacity: 0;
            transition: opacity 0.5s ease;
}
.max-tooltip{
  position: absolute;
    z-index: 1;
    top: -50px!important;
    opacity: 0;
            transition: opacity 0.5s ease;
}
.flow-select{
  outline: none;
    border: none;
    border-bottom: 1px solid #B2AFB6;;
    width: 100%;
    padding: 6px 0px;
    transition:0.5s ease all
}
.flow-select:focus{

    border-bottom: 1px solid #4D1B7E;;
   
}
</style>
